import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@glide-finance/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

// background-color: ${({ theme }) => theme.colors.background};

// const GlobalStyle = createGlobalStyle`
//   * {
//     font-family: 'Kanit', sans-serif;
//   }
//   body {
//     position: fixed;
//     inset: 0px;
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-image: url('/images/background-dark-1.png');

//     img {
//       height: auto;
//       max-width: 100%;
//     }
//   }
// `

// const GlobalStyle = createGlobalStyle`
//   * {
//     font-family: 'Kanit', sans-serif;
//   }
//   body {
//     position: fixed;
//     inset: 0px;
//     background-size: cover;
//     background-repeat: no-repeat;
//     background-image: url('/images/background-dark.png');

//     img {
//       height: auto;
//       max-width: 100%;
//     }
//   }
// `

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Nunito', sans-serif;
  }
  body {
    background-color: #060914;

    img {
      height: auto;
      max-width: 100%;
    }
  }
`

export default GlobalStyle
